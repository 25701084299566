import { applyMiddleware, combineReducers, createStore } from "redux";
import initialReducer from "./initialReducer";
import thunk from 'redux-thunk'
import Login_Singup_Reducer from "./Login_Signup_Reducer";
import User_Location_Reducer from "./User_Location_Reducer";
import searchReducer from "./search/searchReducer"
import themeReducer from "./themeMode/themeReducer";
import sortNfilterReducer from "./newUpdate/sortNfilterReducer";
import Farms_Reducer from "./Farms_Reducer.js";
import Farm_VisitID_Reducer from "./Farm_VisitID_Reducer.js";
import { myRideReducer } from './myrides/reducermyride.js';
import { sessionReducer } from 'redux-react-session';
import { sessionService } from "redux-react-session";

//Do not change anything and use combine reducer to combine and provide your reducer to the app.

const rootReducer = combineReducers({
    initialReducer,
    LoginSignupRed: Login_Singup_Reducer,
    searchReducer,
    themeReducer,
    sortNfilterReducer,
    myRideReducer,
    UserLocationRed: User_Location_Reducer,
    FarmsRed: Farms_Reducer,
    FarmVisitIDRed: Farm_VisitID_Reducer,
    session: sessionReducer
});


const Store = createStore(rootReducer, applyMiddleware(thunk))

sessionService.initSessionService(Store);

const validateSession = (session) => {
    // check if your session is still valid
    return true;
  }
  
const options = { refreshOnCheckAuth: true, redirectPath: '/farms', driver: 'COOKIES', validateSession };

sessionService.initSessionService(Store, options)
.then(() => console.log('Redux React Session is ready and a session was refreshed from your storage'))
.catch(() => console.log('Redux React Session is ready and there is no session in your storage'));

export const store = createStore(rootReducer, applyMiddleware(thunk))

