export const REQUEST_MYRIDES='REQUEST_MYRIDES'
export const ADD_MYRIDES='ADD_MYRIDES'
export const ERROR_MYRIDES='ERROR_MYRIDES'

export const LOCATION_TAG_ADD = 'LOCATION_TAG_ADD'
export const LOCATION_TAG_REMOVE = 'LOCATION_TAG_REMOVE'
export const LOCATION_TAG_CLEAR = 'LOCATION_TAG_CLEAR'

export const BIKE_TAG_ADD = 'BIKE_TAG_ADD'
export const BIKE_TAG_REMOVE = 'BIKE_TAG_REMOVE'
export const BIKE_TAG_CLEAR = 'BIKE_TAG_CLEAR'

export const STATUS_ALL='STATUS_ALL';
export const STATUS_PENDING='STATUS_PENDING';
export const STATUS_CANCEL='STATUS_CANCEL';
export const STATUS_COMPLETE='STATUS_COMPLETE';
