const userLocation = {
    isCheckedIn:false,
    geolocation:"",
    geoAddress:"",
}

const User_Location_Reducer = (state={userLocation},action) =>{
    if(action.type === "ISCHECKEDIN"){
        return{
            ...state,
            isCheckedIn:action.payload.isCheckedIn,
            geolocation:action.payload.geolocation,
            geoAddress:action.payload.geoAddress
        }
    }else if(action.type === "ISCHECKEDOUT"){
        return{
            ...state,
            isCheckedIn:false,
            geolocation:"",
            geoAddress:""
        }
    }


    return state;
}
export default User_Location_Reducer;