/*
main display 
*/

import React, { Component, createRef } from 'react';
import Map2 from './Map2';
import './clock.png' 
import { services } from '@tomtom-international/web-sdk-services'
import { NotificationContainer, NotificationManager } from 'react-notifications';
import FarmsTable from './FarmsTable';


export class MainCanvas extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            providerName: '',
            providerEmail: '',
            providerPhone: '',
            providerAddress: '',
            providerLocation: '',
            currentProviders: [],
            TT_APIKEY: 'wNwKO5fgWhDOBLlrpOLeXaS6a5iES9Nh',
            searchList: [],
            packageName: '',
            providerCode: '',
            providerId: '',
            customerId: '',
            vehicleId: '',
            vehicleType: '',
            packageFragile: false,
            packagePerishable: false,
            packageHeavy: false,
            customerName: '',
            customerEmail: '',
            customerPhone: '',
            deliveryAddress: '',
            deliveryLocation: '',
            vehicles:[],
            customers: [],
            providers: [],
            orders2: [],
            isSocketConnected: false,
            notificationMsg: [],
            notificationType: '',
            searched: ''
        }

        this.setProvider = this.setProvider.bind(this);
        this.setEmail = this.setEmail.bind(this);
        this.setPhone = this.setPhone.bind(this);
        this.setAddress = this.setAddress.bind(this);
        this.setLocation = this.setLocation.bind(this);
        this.handleResult = this.handleResult.bind(this);
        this.searchLocation = this.searchLocation.bind(this);
        this.searchLocation2 = this.searchLocation2.bind(this);
        this.autocompleteAddress = this.autocompleteAddress.bind(this);
        this.autocompleteAddress2 = this.autocompleteAddress2.bind(this);
        this.setPackageName = this.setPackageName.bind(this);
        this.setProvCode = this.setProvCode.bind(this);
        this.setProvId = this.setProvId.bind(this);
        this.setCustId = this.setCustId.bind(this);
        this.setVehicleId =  this.setVehicleId.bind(this)
        this.setVehicleType =  this.setVehicleType.bind(this)
        this.setFragile = this.setFragile.bind(this);
        this.setPerishable = this.setPerishable.bind(this);
        this.setHeavy = this.setHeavy.bind(this);
        this.setCustName = this.setCustName.bind(this);
        this.setCustEmail = this.setCustEmail.bind(this);
        this.setCustCell = this.setCustCell.bind(this);
        this.setDelivAddress = this.setDelivAddress.bind(this);
        this.confirmOrder = this.confirmOrder.bind(this);
        this.mapRef = createRef();
        this.notificationWrapper = createRef()
        this.createNotification = this.createNotification.bind(this);
        this.createNotification2 = this.createNotification2.bind(this);
    }

    createNotification = (type, feedback) => {

        let messageData = this.state.notificationMsg
        let notification = (messageData.toString()).replace('}', '').replace('{', '').replace('"', '').replace(',', `${'\n'}`)

        return () => {

            switch (type) {
                // case 'info':
                // NotificationManager.info('Socket-connection established', 'Socket', 3000);
                // break;
                case 'success':
                NotificationManager.success('Status Update', 'Notification',15000, () => {
                    document.getElementById('notificationBtn').toggleAttribute('hidden');
                    alert(feedback)
                    
                });
                break;
                case 'warning':
                NotificationManager.warning('Socket connection closed', 'Socket', 3000);
                break;
                case 'error':
                NotificationManager.error('Error message', 'Error', 5000, () => {
                    alert(feedback);
                });
                break;
                default: break;
            }
        }
    }

    createNotification2 = (type, feedback) => {

        let messageData = this.state.notificationMsg
        let notification = (messageData.toString()).replace('}', '').replace('{', '').replace('"', '').replace(',', `${'\n'}`)

        switch (type) {
            case 'info':
            NotificationManager.info('Socket-connection established', 'Socket', 3000);
            break;
            case 'warning':
            NotificationManager.warning('Socket connection closed', 'Socket', 3000);
            break;
            case 'success':
            NotificationManager.success('Status Update', 'Notification',15000, () => {
                document.getElementById('notificationBtn').toggleAttribute('hidden');
                alert(feedback)
                alert(notification)
                
            });
            NotificationManager.error('Error message', 'Error', 5000, () => {
                alert(feedback);
            });
            default: break;
        }
    }

    confirmOrder(e) {
        e.preventDefault();

        let customers = this.state.customers;
        let custId = this.state.customerId
        const selectedCustomer = () => customers.filter((customer) => custId === customer.id)
        let providers = this.state.providers;
        let provId = this.state.providerId
        const selectedProvider = () => providers.filter((provider) => provId === provider.id)

        let orderConfirmation = window.confirm(`
            Order Confirmation
 
            Provider ID: ${this.state.providerId}
            Provider Name: ${this.state.providerName}
            Provider Email: ${selectedProvider.email}
            Pickup Address: ${selectedProvider.address}
            Provider Phone: ${selectedProvider.cell_phone}
  
            Package Title: ${this.state.packageName}
            Fragile: ${this.state.packageFragile}
            Perishable: ${this.state.packagePerishable}

            Vehicle Type: ${this.state.vehicleType}

            Customer ID: ${this.state.customerId}
            Customer Name: ${this.state.customerName}
            Customer Email: ${selectedCustomer.email}
            Customer Phone: ${selectedCustomer.cell_phone}
            Delivery Address: ${selectedCustomer.address}
        `
        )
        // TODO: Ops comments 
        if(orderConfirmation) {
            // TODO: post to database and update order cards

            // new provider
            const providerBody =  {
                // provider_id: this.state.providerId,
                // contact_person
                name: this.state.providerName,
                email: this.state.providerEmail,
                address: this.state.providerAddress,
                contact: this.state.providerPhone, //cell_phone 
                // provider_code: this.state.providerCode,
                // location: this.state.provierLocation
            };

            const deliveryBody =  {
                provider_id: this.state.providerId,
                // provider_name: this.state.providerName,
                // provider_email: this.state.providerEmail,
                // provider_address: this.state.providerAddress,
                // provider_contact: this.state.providerPhone,
                // provider_code: this.state.providerCode,
                title: this.state.packageName,
                // title: this.state.deliveryTitle
                // is_fragile: this.state.packageFragile,
                // is_perishable: this.state.packagePerishable,
                vehicle_type: this.state.vehicleType,//"motorbike", // required_vehicle_type: (dropDown)
                customer_id: this.state.customerId,
                // driver_id: 1
            };

            const customerBody = {
                // customer_id: this.state.customerId,
                name: this.state.customerName,
                email: this.state.customerEmail,
                phone: this.state.customerPhone, //cell_phone
                address: this.state.deliveryAddress 
                // location: this.state.customerLocation
            }

            

        
            try {
                fetch('http://localhost:5054/api/delivery', {
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: 'POST',
                    body: JSON.stringify(deliveryBody)
                })
                .then(res => res.json())
                .then((data) => {
                    // console.log(data);
                    // this.setState({orders: [...this.state.orders, data]});
                    alert("Order was successfully added.")

                })
                .finally(() => {
                    
                    document.location.reload();
                })
            }catch(err) {
                console.log(err)
            }
        }
            
        else {
            alert("Order cancelled")
        }
    }

    // this.setCustName = this.setCustName.bind(this);
    // this.setCustEmail = this.setCustEmail.bind(this);
    // this.setCustCell = this.setCustCell.bind(this);
    // this.setDelivAddress = this.setDelivAddress.bind(this);

    setCustName(e) {
        this.setState({customerName: e.target.value});
    }

    setCustEmail(e) {
        this.setState({customerEmail: e.target.value});
    }

    setCustCell(e) {
        this.setState({customerPhone: e.target.value});
    }

    setDelivAddress(e) {
        this.setState({deliveryAddress: e.target.value});
    }

    setDeliveryLocation() {
        // TODO: get delivery location (coordinates) from searh response 
    }

    setPackageName (e) {
        this.setState({packageName: e.target.value});
    }

    setProvCode (e) {
        this.setState({providerCode: e.target.value});
    }

    setProvId (e) {
        this.setState({providerId: e.target.value});
    }

    setCustId (e) {
        this.setState({customerId: e.target.value});
    }

    setVehicleId(e) {
        this.setState({vehicleId: e.target.value, vehicleType:e.target.key});
    }
    setVehicleType(e) {
        this.setState({vehicleType: e.target.value});
    }

    setFragile () {

        let packageFragile = this.state.packageFragile;

        this.setState({packageFragile: !packageFragile});
    }

    setPerishable () {

        let packagePerishable = this.state.packagePerishable;

        this.setState({packagePerishable: !packagePerishable});
    }

    setHeavy () {

        let packageHeavy = this.state.packageHeavy;

        this.setState({packageHeavy: !packageHeavy});
    }

    setProvider(e) {
        this.setState({providerName: e.target.value});
    }

    setEmail(e) {
        this.setState({providerEmail: e.target.value});
    }

    setPhone(e) {
        this.setState({providerPhone: e.target.value});
    }

    setAddress(e) {
        this.setState({providerAddress: e.target.value});
    }

    setLocation(e) {
        // TODO: get coordinates from location search

        this.setState({providerLocation: e.target.value});
    }

    // fuzzy search results
    handleResult(result) {
        // console.log(result);
        if(result.results.length > 0) {
            this.setState({
                searchList: [...this.state.searchList, result]
            });
        }
        // this.moveMapTo(result.results[0].position);
        // this.addMarker(result.results[0].position);
    }

    // search box
    async searchLocation() {
        const TT_APIKEY = this.state.TT_APIKEY

        this.setState({ searchList: []});

        const response = await services.fuzzySearch({
            key: TT_APIKEY,
            query: document.getElementById('query2').value,
            limit: 5,
            language: 'en-GB',
            typeahead: true,
        })
        this.handleResult(response)
    }

    async searchLocation2() {
        const TT_APIKEY = this.state.TT_APIKEY

        this.setState({ searchList: []});

        const response = await services.fuzzySearch({
            key: TT_APIKEY,
            query: document.getElementById('query3').value,
            limit: 5,
            language: 'en-GB',
            typeahead: true,
        })
        this.handleResult(response)
    }


    // autocomplete
    async autocompleteAddress() {  
        const TT_APIKEY = this.state.TT_APIKEY
        const response = await services.autocomplete({
            key: TT_APIKEY,
            query: document.getElementById('query3').value,
            language: 'en-GB',
            limit: 5,
            typeahead: true
        })
        // console.log(response)
    }

    async autocompleteAddress2() {  
        const TT_APIKEY = this.state.TT_APIKEY
        const response = await services.autocomplete({
            key: TT_APIKEY,
            query: document.getElementById('query3').value,
            language: 'en-GB',
            limit: 5,
            typeahead: true
        })
        // console.log(response)
    }


    render() {

        const { searchList, providerLocation, providers, customers, vehicles, notificationType } = this.state;
   
        return (
            <div
                className='container-fluid'
                style={{
                    marginTop: '15px',
                    height: '100%',
                    width: '100%',
                    borderRadius: '5px',
                    boxShadow: 'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px'
            }}>
                <div>
                    {
                        notificationType === 'on_message' ? (
                        
                            <button className='btn btn-primary' id ="notificationBtn"
                                onClick={this.createNotification('success')}>Notifications
                            </button>
                            
                        )
                        : null
                    }
                    <NotificationContainer ref={this.notificationWrapper}/>
                </div>
                <div className='row' style={{position:'static', height:'5vh'}}>
                    <div className="col-lg-12 col-sm-12 column" style={{backgroundColor: 'white'}}>
                        <button className='btn btn-outline-primary' size='medium' style={{width: "100%", marginTop:"3px"}} >
                            <a href="#farmDiv">farm search</a>
                        </button>
                    </div>
                </div>
                <div className="row" style={{minWidth: '85vw', height: '80vh',postion:'absolute' }}>
                    
                    <Map2 />
                    
                    <div className="col-lg-5 col-sm-5 column" id="progressColumn" style={{backgroundColor: 'white'}}>
                        <h4 className='grid-heading' style={{backgroundColor: 'none', color: "charcoal", marginTop: '2.5px'}}><b>My Farms</b></h4>
                        <hr></hr>
                        {/* <ProgressBar /> */}
                        <div id ="farmDiv" className="headingContainer" style={{position:'sticky', zIndex: 1,  width: '100%', marginBottom: 1}}>

                            <FarmsTable />
                        </div>
                    </div>

                </div> 
            </div>
        )
    }
}

export default MainCanvas;
