import { Box } from "@chakra-ui/react";
import React from "react";
import MainCanvas from "./MainCanvas"

function TariffPage(props) {
    
  return (
    <Box margin={"20px"}>
      <Box mt={10} mb={10} alignSelf={"center"}>
        <MainCanvas />
      </Box>
    </Box>
  );
}

export default TariffPage;
