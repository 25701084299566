import React from 'react';
// import { DataGrid, GridToolbar  } from '@mui/x-data-grid';
import { DataGrid, GridToolbar, GridToolbarExport, GridToolbarDensitySelector } from '@mui/x-data-grid';


const DataTable = ({
    rows, 
    columns, 
    loading, 
    sx, 
    pageSize,
    onRowClick,
    checkboxSelection,
}) => {

    return (
        <DataGrid
            {...rows}
            initialState={{
            ...rows.initialState,
            pagination: { paginationModel: { pageSize: 10 } },
            }}
            rows={rows}
            columns={columns}
            loading={loading}
            sx={sx}
            checkboxSelection={checkboxSelection}
            pagination
            pageSize={pageSize}
            components={{ Toolbar:  GridToolbarExport}}
            onRowClick={onRowClick}
            getRowId={rows => rows.farm_id}
            rowBuffer={10}
        />
    )
}

export default DataTable;