export const GET_DATA_REQUEST = 'GET_DATA_REQUEST'
export const GET_DATA_SUCCESS = 'GET_DATA_SUCCESS'
export const GET_DATA_FAILURE = 'GET_DATA_FAILURE'

export const SORT_DATA_REQUEST = 'SORT_DATA_REQUEST'
export const SORT_DATA_SUCCESS = 'SORT_DATA_SUCCESS'
export const SORT_DATA_FAILURE = 'SORT_DATA_FAILURE'

export const LOCATION_TAG_ADD = 'LOCATION_TAG_ADD'
export const LOCATION_TAG_REMOVE = 'LOCATION_TAG_REMOVE'

export const BIKE_TAG_ADD = 'BIKE_TAG_ADD'
export const BIKE_TAG_REMOVE = 'BIKE_TAG_REMOVE'

export const LOCATION_FILTER_DATA = 'LOCATION_FILTER_DATA'

export const RENTAL_DETAILS_REQUEST = "RENTAL_DETAILS_REQUEST"
export const RENTAL_DETAILS_SUCCESS = "RENTAL_DETAILS_SUCCESS"
export const RENTAL_DETAILS_ERROR = "RENTAL_DETAILS_ERROR"

export const SET_DURATION = "SET_DURATION"

export const FILTER_DATA = "FILTER_DATA"

export const CHECKOUT_OBJECT = "CHECKOUT_OBJECT"

