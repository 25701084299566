import { ChevronDownIcon } from "@chakra-ui/icons";
import { Box, Flex, Heading, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Notifications from 'react-notifications-menu';


function NavWithLogin() {

    const userName = useSelector(state=>state.LoginSignupRed.name);
  

    return (
        
        <Box h='50px' w='auto' pr={8} >

            <Flex justifyContent='space-evenly'>
                
                {/* <Text pt='25px' fontSize='xl' fontWeight={"bold"}>{userName}</Text> */}
                <Box pt='10px'><ChevronDownIcon fontSize='2xl'/></Box>
            </Flex>
        </Box>
        
        
    )
}
export default NavWithLogin;