import tt, {Control, CustomLayer} from '@tomtom-international/web-sdk-maps';
import { services } from '@tomtom-international/web-sdk-services';

class SearchMarker {
    constructor(poiData, options) {
        this.poiData = poiData;
        this.options = options || {};
        this.marker = new tt.Marker({
            element: this.createMarker(),
            anchor: "bottom",
        });
        var lon = this.poiData.position.lng || this.poiData.position.lon;
        this.marker.setLngLat([lon, this.poiData.position.lat]);
    }
    addTo(map) {
        this.marker.addTo(map);
        this._map = map;
        return this;
    }
    createMarker() {
        var elem = document.createElement("div");
        elem.className = "tt-icon-marker-black tt-search-marker";
        if (this.options.markerClassName) {
            elem.className += " " + this.options.markerClassName;
        }
        var innerElem = document.createElement("div");
        innerElem.setAttribute(
            "style",
            "background: white; width: 10px; height: 10px; border-radius: 50%; border: 3px solid black;"
        );

        elem.appendChild(innerElem);
        return elem;
    }
    remove() {
        this.marker.remove();
        this._map = null;
    }
}

export default SearchMarker;