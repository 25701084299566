const fVisitID = {
    visitID: null
}

const Farm_VisitID_Reducer = (state={fVisitID},action) =>{
    if(action.type === "SETVISITID"){
        return{
            ...state,
            visitID:action.payload.visitationId,
        }
    }else if(action.type === "RESETVISITID"){
        return{
            ...state,
            visitID: null,
        }
    }


    return state;
}
export default Farm_VisitID_Reducer;