const farmFilter = {
    viewFarms: ""
}

const Farms_Reducer = (state={farmFilter},action) =>{
    if(action.type === "FARMSFILTER"){
        return{
            ...state,
            viewFarms:action.payload.thesefarms,
        }
    }else if(action.type === "FARMSCLEARFILTER"){
        return{
            ...state,
            viewFarms:""
        }
    }

    return state;
}
export default Farms_Reducer;