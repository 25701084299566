import tt, {Control, CustomLayer} from '@tomtom-international/web-sdk-maps';
import { services } from '@tomtom-international/web-sdk-services';
import SearchMarker from './SearchMarker';

class SearchMarkersManager {
    constructor(map, options) {
        this.map = map;
        this._options = options || {};
        this._poiList = undefined;
        this.markers = {};
    }
    draw(poiList) {
        this._poiList = poiList;
        this.clear();
        this._poiList.forEach(function (poi) {
            var markerId = poi.id;
            var poiOpts = {
                name: poi.poi ? poi.poi.name : undefined,
                address: poi.address ? poi.address.freeformAddress : "",
                distance: poi.dist,
                classification: poi.poi ? poi.poi.classifications[0].code : undefined,
                position: poi.position,
                entryPoints: poi.entryPoints,
            };
            var marker = new SearchMarker(poiOpts, this._options);
            marker.addTo(this.map);
            this.markers[markerId] = marker;
        }, this);
    }
    clear() {
        for (var markerId in this.markers) {
            var marker = this.markers[markerId];
            marker.remove();
        }
        this.markers = {};
        this._lastClickedMarker = null;
    }
}


export default SearchMarkersManager;