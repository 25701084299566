import CTA from "../CTA";


function Home(props){
    return (
        <div>
            {/* Home */}
            <CTA />
            {/* <Fleet /> */}
            {/* <LandingSlider /> */}
            {/* <LogoSlider /> */}
        </div>
    );
}

export default Home
