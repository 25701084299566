import React, { useEffect, useState, useCallback } from "react";
import { IconMenu2 } from "@tabler/icons-react";
import styled from "styled-components";
import {
	Button,
	Box,
	Drawer,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	DrawerHeader,
	DrawerBody,
	Input,
	DrawerFooter,
	Text,
	Divider,
} from "@chakra-ui/react";
import { ChevronDownIcon, MoonIcon } from "@chakra-ui/icons";
import { useDisclosure, IconButton } from "@chakra-ui/react";
import { useRef } from "react";
import NavWithLogin from "./Nav_with_Login";
import NavWithoutLogin from "./Nav_with_out_Login";
import { setLightMode, setDarkMode } from "../../Redux/themeMode/action";
import { Badge } from "@chakra-ui/react";
import Notifications from "react-notifications-menu";
import { Link } from "react-router-dom";
import CityModal from "../CityModal";

import { useDispatch, useSelector } from "react-redux";
import ProfileMenu from "../Login/ProfileMenu";
import zIndex from "@mui/material/styles/zIndex";

function Navbar(props) {

	// const [selectedCity, setSelectedCity] = useState("Search School");
	const [visitStatus, setVisitStatus] = useState([]);
	const [visitData, setVisitData] = useState([]);
	const userName = useSelector(state => state.LoginSignupRed.name);
	// console.log(userName)
	const isUserLoggedIn = useSelector((storeData) => {
		return storeData.LoginSignupRed.isLoggedIn

	});

	const getVisitationStatus = useCallback(async() => {

		// let notificationIdx = 0;
		let prelimNotifiList = [];

		fetch(`https://jdi-staging.maps.geoint.africa/jdipwa/api/visit-status/${userName}`)
			.then((res) => res.json())
			.then((data) => {


				setVisitStatus(data)
				// console.log(actualData)

				var today = new Date();
				var nextMonth = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 30);
				// var nextWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate()+7);
				//console.log(`${dateTimeAF}  |  ${nextMonth}`);

				// eslint-disable-next-line array-callback-return
				data.map((farm, indx) => {
					if (farm.visit_status === "Lapsed") {

						// notificationIdx += 1;

						// let record = {id: notificationIdx, note: farm.farm_name};

						let record = {
							image: "urgent.png",
							message: `${farm.business_name}, is overdue for a visit. - ${indx}`,
							detailPage: "#",
							receivedTime: null,
							key: indx
						}

						prelimNotifiList.push(record);

					}
					else if (farm.visit_status === "On Track") {

						// notificationIdx += 1;

						// let record = {id: notificationIdx, note: farm.farm_name};

						// {image, message ,detailPage, receivedTime} - schema for notification data

						let record = {
							image: "reminder-icon.png",
							message: farm.future_visit === null ? `${farm.business_name}, has an upcoming visitation` : `${farm.business_name}, has an upcoming visitation on ${farm.future_visit}.`,
							detailPage: "#",
							receivedTime: null,
							key: indx
						}

						if (farm.future_visit < nextMonth) {
							prelimNotifiList.push(record);

						}
					}
				})
			})

		setVisitData(prelimNotifiList);
	}, [userName])

	useEffect(() => {

		if(isUserLoggedIn) {
			
				getVisitationStatus()
		
		}
			
	}, [getVisitationStatus, isUserLoggedIn]);


	const { isOpen, onOpen, onClose } = useDisclosure();
	const { isOpen: isModalOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure({ defaultIsOpen: false });

	// useEffect(() => { onModalClose(true) }, [])

	const btnRef = React.useRef()

	const isDark = useSelector(state => state.themeReducer.dark);
	const dispatchTheme = useDispatch();

	function updateTheme() {
		// console.log(isDark)
		if (isDark) {
			dispatchTheme(setLightMode());
		} else {
			dispatchTheme(setDarkMode());
		}
	}
	// console.log("Checking login auth", isUserLoggedIn)

	return (
		<Container isDark={isDark}>
			<Drawer
				isOpen={isOpen}
				placement="left"
				onClose={onClose}
				finalFocusRef={btnRef}
			>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader></DrawerHeader>

					<DrawerBody>
						{/* <Input placeholder='Type here...' /> */}
						<DrawerButtons>
							<DrawerCard>
								<Link to="/" isOpen={isOpen}>Home - login</Link>
							</DrawerCard>
							{/* <DrawerCard>
                <Link to="/admin">admin</Link>
              </DrawerCard> */}
							{/* <DrawerCard>
                <Link to="/">Logout</Link>
              </DrawerCard> */}
							{/* <DrawerCard>Partner With You</DrawerCard>
              <DrawerCard><Link to='/biketour'>Bike Tour</Link></DrawerCard>
              <DrawerCard>Indian Bike Routes</DrawerCard>
              <DrawerCard>About Us</DrawerCard>
              <DrawerCard>Terms & Condition</DrawerCard>
              <DrawerCard>Privacy Policy</DrawerCard>
              <DrawerCard>FAQ</DrawerCard>
              <DrawerCard>Reach Us</DrawerCard> */}
						</DrawerButtons>
					</DrawerBody>

					<DrawerFooter></DrawerFooter>
				</DrawerContent>
			</Drawer>
			{/* Modal */}

			{/* <Modal isOpen={isModalOpen} onClose={onModalClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalContainer>
                        <ModalNavbar>
                            
                            <img src="https://d36g7qg6pk2cm7.cloudfront.net/assets/landing_page/royal_brothers_logo-229959d7727f356b2e4fc3bd9c0c527c60127d009c93989a93e2daa0b1c2d556.svg" alt="" />
                                                      
                            <Box borderLeft="1px solid #000000" height="100%" />
                            <Text>Bike Rentals</Text>
                        </ModalNavbar>
                        <ModalSearch>ssearch</ModalSearch>
                        <ModalBody>Body</ModalBody>
                    </ModalContainer>
                </ModalContent>
            </Modal> */}
			{/* <CityModal isOpen={isModalOpen} setIsOpen={onModalClose} setSelectedCity={setSelectedCity} /> */}
			{/* <Modal isOpen={isModalOpen} onClose={onModalClose}>
                Hello Modal
            </Modal> */}
			<Left>
				<MenuBtn ref={btnRef} onClick={onOpen}>
					<IconMenu2 size={"32px"} color={isDark ? 'white' : 'white'} />
				</MenuBtn>
				<Link to='/'><img src="../../JDI_Logo_Main.png" alt="" style={{ height: "30px", width: "80px" }} /></Link>
			</Left>
			<Center >
				<CustomButton className="Center">
					<Link to='/'> <b>JD Implemente Marketing</b></Link>
				</CustomButton>
				{/* <CustomButton className="Center"><Link to='/search'>Locate me</Link></CustomButton> */}
				{/* <CustomButton className="Center">Admin</CustomButton>
                <CustomButton className="Center">What's new?</CustomButton> */}
			</Center>
			<Right isDark={isDark}>
				{/* <Button rightIcon={<ChevronDownIcon />} leftIcon={<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
                </svg>
                } bg={"#EEEEEE"} colorScheme="gray" variant='outline' onClick={onModalOpen}>
                    {selectedCity}
                </Button> */}

				
				<Box className="divider" borderLeft="1px solid rgba(0,0,0,0.2)" height="100%" padding="4px"/>
				<Box>
					{
						isUserLoggedIn ? <ProfileMenu /> : <NavWithoutLogin />
					}
				</Box>
				{isUserLoggedIn ?
					<CustomButton
						
						onClick={(e) => getVisitationStatus} 
						style={{borderRadius: "5%", height: "70%", marginTop:1}}

					>
						<Badge ml='4' zIndex={999} mt={4} colorScheme='red' borderRadius={"40%"}>
							{visitData.length}
						</Badge>
						<Notifications
							data={visitData}
							header={{
								title: 'Visitation Notifications',
								option: { text: 'View All', onClick: (e) => getVisitationStatus }
							}}
							width={'75%'}
							style={{ zIndex: 888, marginRight: "8px", borderRadius: "5%"}}
							height={'95%'}
							// classNamePrefix='okrjoy'
							// cardOption={data => console.log(data)}
							// viewAllbtn={{ text: 'see all', onClick: () =>  getVisitationStatus}}
							// markAsRead={data => console.log(data)}
							// headerBackgroundColor = 'green'
							// className='okrjoy'
						/>
						

					</CustomButton>
					: null
				}
				{/* <IconButton
					colorScheme={!isDark ? 'blackAlpha' : 'whiteAlpha'}
					aria-label='Search database'
					icon={<MoonIcon />}
					onClick={updateTheme}
				/> */}
			</Right>
		</Container >
	);
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background: ${(props) => (props.isDark ? "#4D862D" : "linear-gradient(-45deg,#4D862D 10%, #2D363D)")};
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
`;

const ModalContainer = styled.div``;
const ModalNavbar = styled.div`
  & > img {
    width: 100px;
  }
`;
const ModalSearch = styled.div``;
const ModalBody = styled.div``;

const DrawerButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const DrawerCard = styled.div`
  width: 100%;
  background-color: #EEEEEE;
  padding: 10px 0 10px 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  font-size: 0.9rem;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  img {
    width: 120px;
  }
`;

const MenuBtn = styled.div``;

const Center = styled.div`
  display: flex;
  gap: 30px;
  font-size: 1.0rem;
  color: ${(props) => (props.isDark ? "white" : "white")};
  @media (max-width: 850px) {
    display: none;
  }
  
`;


  
// gap: 10px;
const Right = styled.div`
  display: flex;

  .authButton {
    @media (max-width: 850px) {
      display: none;
    }
  }

  .loginButton {
    color: ${(props) => (props.isDark ? "white" : "#2C3333")};
  }

  .divider {
    @media (max-width: 850px) {
      display: none;
    }
  }

`;

const CustomButton = styled.button``;

export default Navbar;
