/*
    This is a datagrid component that will render the deployment data from SAMS DB.
    React useEffect is used as a wrapper around the fetch function to trigger a 
    request to the api on change of the defined condition.
    This is a functional component and, thus, can only accept props not define them.
*/

//models and components to import
import React, { useState, useEffect } from 'react';
import DataTable from '../DataTable';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";
import { 
    Box, Button, Modal, Typography, createTheme, 
    ThemeProvider, IconButton, 
    Input
} from '@mui/material';
import { useToast, CircularProgress, InputGroup } from '@chakra-ui/react';
import CloseIcon from '@mui/icons-material/Close';
import CheckIn from './CheckIn';
import emailModal from './emailModal';
import { services } from '@tomtom-international/web-sdk-services';
import Select from 'react-select'; 


const MuiTheme = createTheme({
    palette: {
      mode: "light",
      warning: {main: '#E86224'},

    },
    components: {
      MuiDataGrid: {
        styleOverrides: {
            root: {   
                border: 1,
                borderColor: "#000",
                borderStyle: "solid",
                borderRadius: 5,
                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
                color: "#00005",
                padding: 3,
            },
           
        },
      },
    },
});  


//define the columns that will be created by mui datagrid
const columns = [
    {field: 'farm_name', headerName: 'Name', width: 80, headerClassName: 'super-app-theme--header'},
    {field: 'business_name', headerName: 'Business Name', width: 180, headerClassName: 'super-app-theme--header'},
    {field: 'lpi', headerName: 'LPI', width: 180, headerClassName: 'super-app-theme--header'},
    {field: 'farm_profile', headerName: 'Profile', width: 80, headerClassName: 'super-app-theme--header'},
    {field: 'branch', headerName: 'Branch', width: 180, headerClassName: 'super-app-theme--header'},
    {field: 'marketer_fullname', headerName: 'Marketer', width: 150, headerClassName: 'super-app-theme--header'}
];


//styling for datagrid
const deployTableStyles = {
    
    height: '91%',
    width: '100%',
}

// styling for Modal
const style = {
    position: 'absolute',
    top: '100%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80vw",
    bgcolor: 'background.paper',
    borderColor: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflowY: 'scoll'
};


//component that will be rendered
//takes in the token as a prop that is used to authenticate the user's session and api access
const FarmsTable = () => {

    // current date-time
    let today = new Date();
    let hours = today.getHours() + 2;
    let time = `${hours}:${today.getMinutes()}:${today.getSeconds()}`;
    function convertTZ(date, tzString) {
        return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
    }
    // Tz function
    let dateTimeAF = convertTZ(today, "Africa/Gaborone") 

    //set state properties for organisation array which will be retrieved from db
    const [farms, setFarms] = useState([]);
    const [fetching, setFetching] = useState(true);
    const [checkboxSelection, setCheckboxSelection] = useState(false);
    const [rowParams, setParams] = useState([]);
    const [open, setOpen] = useState(false);
    const [emailFormOpen, setEmailFormOpen] = useState(false);
    const [formOpen, setFormOpen] = useState(false);
    const [businessName, setBusinessName] = useState("");
    const [profile, setProfile] = useState("");
    const [farmAddress, setFarmAddress] = useState("");
    const [province, setProvince] = useState("");
    const [newBusiness, setNewBusiness] = useState(false);
    const [cellNumber, setCellNumber] = useState("");
    const [email, setEmail] = useState("");
    const [reasonFVisit, setReasonFVisit] = useState('');
    const [madeEdits, setMadeEdits] = useState(false);
    const [comments, setComments] = useState('');
    const [loContactName, setLoContactName] = useState("");
    const [loContactSurname, setLoContactSurname] = useState("");
    const [searched, setSearched] = useState("");
    const [farmName, setFarmName] = useState("");
    const [farmType, setFarmType] = useState("");
    const [farmId, setFarmID] = useState(0);
    const [searchCategory, setCategory] = useState("");
    const [clients, setClients] = useState([]);
    const [additionalNumber, setAdditionalNumber] = useState("");
    const [leaseType, setLeaseType] = useState("");
    const [visitStatus, setVisitStatus] = useState([]);
    const [notificationList, setNotification] = useState([]);
    const [notificationMail, setNotiMAil] = useState("");
    const [emailContent, setEmailContent] = useState("");
    const [suInProg, setSip] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const TT_APIKEY = 'wNwKO5fgWhDOBLlrpOLeXaS6a5iES9Nh';
    const [storedFilter, setStoredFilter] = useState("");
    const [selectedOptions, setSelectedOptions] = useState([]);
    
    //simple state functions
    const handleOpen = () => {

        setBusinessName(rowParams.row.business_name === null ? "" : rowParams.row.business_name);
        setFarmName(rowParams.row.farm_name === null ? "" : rowParams.row.farm_name);
        setLoContactName(rowParams.row.contact_name === null ? "" : rowParams.row.contact_name);
        setLoContactSurname(rowParams.row.client_surname === null ? "" : rowParams.row.client_surname);
        setFarmAddress(rowParams.row.address === null ? "" : rowParams.row.address);
        setCellNumber(rowParams.row.phone === null ? "" : rowParams.row.phone);
        setEmail(rowParams.row.email === null ? "" : rowParams.row.email);
        setOpen(true);
        // console.log(params)
        
    };
    const handleClose = () => {setOpen(false); setFormOpen(false); resetState()};
    const handleFormClose = () => {setFormOpen(!formOpen); resetState()}
    const handleEmailFormOpen = () => {setEmailFormOpen(true)}
    const handleEmailFormClose = () => {setEmailFormOpen(false)}

    const toast = useToast()

    const userName = useSelector(state=>state.LoginSignupRed.name);
    const userID = useSelector(state=>state.LoginSignupRed.password);
    const userLocation = useSelector(state=>state.UserLocationRed.geolocation);
    const userGeoAddress = useSelector(state=>state.UserLocationRed.geoAddress);
    const isUserAdmin = useSelector(state=>state.LoginSignupRed.isAdmin);
    const viewFarms = useSelector(state=>state.FarmsRed.viewFarms);
    // fuzzy search results
    const handleResult = (result) =>{
        // console.log(result.results[0].address);
        if (result.results.length >= 1 && result.results.length <= 10) {
            setSearchResults([result.results[0]]);
            setFarmAddress(result.results[0].address.freeformAddress)
        }
    }
    
    // search using Tomtom Search API
    const searchLoc = async () => {
        
        setSearchResults([]);

        const response = await services.fuzzySearch({
            key: TT_APIKEY,
            query: document.getElementById('farmAddress').value,
            limit: 10,
            language: 'en-GB',
            typeahead: true,
            countrySet: 'ZAF'
        })
        handleResult(response)
    }

    const handleAddressClick = (e) => {

        document.getElementById("farmAddress").value=e.target.value

        setFarmAddress(e.target.value);
        setSearchResults([]);
    }

    function handleSelect(dataIn) {
        
        let filteredBusiness;

        setSelectedOptions(dataIn);
        setBusinessName(dataIn.value);
        
        // TODO: client_list format example
        /* 
            f_user_type:       "C",
            marketer:          "Arnold Jordaan",
            branch:            "Bredasdorp",
            f_client_profile:  "JD & OPPI",
            client_name:       "Tan-Jan",
            client_surname:    "van Deventer",
            business_name:     "Raw Boerdery",
            email:             "uitiuuitui.co.za",
            cell_no:           "828 712 330" 
        */

        // use filteredBusiness to check weather the current farm has business associated with it already

        fetch(`https://jdi-staging.maps.geoint.africa/jdipwa/api/associated-farm/${dataIn.value}`)
        .then((res) => res.json())
        .then(data => {
            if(data.isAssociated) {
                window.alert(`WARNING: This business ${dataIn.value} has already been associated with a farm.`);
    
                let confirmNewAssociation = window.confirm(`Would you like to continue?`);
    
                if(confirmNewAssociation) {
                    let suggestedName = `${dataIn.value} - `;
    
                    // setNewBusiness(true);
                    setBusinessName(suggestedName);
    
                    // document.getElementById('newBizInput').setAttribute('checked', true);
                }
                else {
    
                    setTimeout(() => {
                        return window.alert('WARNING: You are overriding this rule.')
                    }, 1000);
                }
            }
            else{
                filteredBusiness = clients.filter((clientRecord, idx) => {

                    return clientRecord.business_name.includes(data.value)
                })

                setCellNumber(filteredBusiness[0].cell_no);
                setEmail(filteredBusiness[0].email);
                setLoContactName(`${filteredBusiness[0].client_name} ${filteredBusiness[0].client_surname}`);
                setLoContactSurname(`${filteredBusiness[0].client_surname}`);
            }
        })
        
        // filter client list on selected business name
        // let filteredBusiness = clients.filter((row) => {
        //     if (row.business_name === undefined || row.business_name === null) {
        //         setLoContactName("not yet defined")
        //         return "not yet defined"
        //     }

        //     return ((row.business_name).toLowerCase()).includes(data.value.toLowerCase())
        // });
        
        // setFarmType(filteredBusiness[0].f_user_type)
        // setProfile(filteredBusiness[0].f_client_profile)
    }

    
    const requestSearch = (searchedVal) => {

        let filteredRows = [];

        switch(searchCategory) {
            case "farm_name":
                // code block
                filteredRows = farms.filter((row) => {
                    if (!row.farm_name){
                        return row.farm_name
                    }
                    else {
                        return ((row.farm_name).toLowerCase()).includes(searchedVal.toLowerCase());
                    }
                });
                break;
            case "LPI":
                // code block

                // TODO: Check if there has been a visitation within the last month
                try {
                    fetch(`https://jdi-staging.maps.geoint.africa/jdipwa/api/visited/${searchedVal}`)
                    .then(res => res.json())
                    .then((data) => {

                        let lastDateVisited = data.lastVisitDate;

                        // Date format example: "2024-06-18T15:59:05.000Z"
                        let convertedDate = convertTZ(lastDateVisited, "Africa/Gaborone");

                        let daysSinceLastVisit = dateTimeAF - convertedDate;

                        console.log(daysSinceLastVisit)

                        if(daysSinceLastVisit < 30) {

                            toast({
                                position: 'top-right',
                                title: 'Visitation Status',
                                description: "Heads up! Looks like this farm has been visited in the last 30 days.",
                                status: 'warning',
                                duration: 5000,
                                isClosable: true,
                                // : notificationAction(farm.future_visit, farm.farm_name)
                            });

                        }
                    })
                } catch (error) {
                    console.log(error.message)
                }


                filteredRows = farms.filter((row) => {
                    if (!row.lpi){
                        return row.lpi
                    }
                    else {
                        return row.lpi.includes(searchedVal);
                    }
                });
                break;
            case "branch":
                // code block
                filteredRows = farms.filter((row) => {
                    return row.branch.includes(searchedVal.toUpperCase());
                });
                break;
            case "business_name":
                // code block
                filteredRows = farms.filter((row) => {
                    if (!row.business_name){
                        return row.business_name
                    }
                    else {
                        return ((row.business_name).toLowerCase()).includes(searchedVal.toLowerCase());
                    }
                });
                break;
            case "marketer":
                // code block
                filteredRows = farms.filter((row) => {
                    return row.username.includes(searchedVal);
                });
                break;
            default:
                console.log('No valid search category selected')
        }
        
        setFarms(filteredRows);
    };
    
    const cancelSearch = () => {
        setSearched("");
        // requestSearch(searched);
        setFetching(true);

        // declare the data fetching function
        const getDataFromAPI = async () => {

            let isUserSuper = isUserAdmin; 
            let replacementName;
            
            if(isUserSuper) {
                replacementName = userName;
            }
            else{
                replacementName = userName;
            }

            fetch(`https://jdi-staging.maps.geoint.africa/jdipwa/api/farms/${replacementName}`) 
            .then((res) => res.json())
            .then((data) => {

                let theData = data.features;

                let filteredData = theData.map((record) => {

                    return record.properties
                })
                // setFarms([filteredData])
                setFarms(filteredData)
                // console.log(filteredData)
            })
            .then(() => {
                setFetching(false)
            })
            .catch((error) => {
                console.error(error)
            });
        }
        // call the function
        getDataFromAPI()
        .catch(console.error);
    };

    const filterOptions = (options, state) => {
        if (!state.inputValue.length) {
          return options;
        }
      
        return options.filter((option) =>
          option.toLowerCase().includes(state.inputValue.toLowerCase())
        );
    };

    //clear state function
    const resetState = () => {
        setBusinessName("");
        setProfile("");
        setFarmAddress("");
        setProvince("");
        setCellNumber("");
        setEmail("");
        setReasonFVisit("");
        setMadeEdits(false);
        setComments("");
        setCheckboxSelection(false);
        setFarmID(0);
        setFarmType("");
        setFarmName("");
        setFarmAddress("");
        setLoContactName("");
        setAdditionalNumber("");
        setSelectedOptions([]);
        setLeaseType("");
        setNewBusiness(false);
        setLoContactSurname("");
        setSip("");
        // document.getElementById('newBizInput').setAttribute('checked', false);
    }

    const notificationAction = async (dateOfVisit, farmName, farmAddress) => {

        let confirmNotification = window.confirm(`
        Would you like to send this reminder to your email?

        Date: ${dateOfVisit},
        Client: ${farmName}
        `);

        if(confirmNotification) {
            let emailConfirmation = prompt(`
            Please enter your email address 
            `);

            setNotiMAil(emailConfirmation)
            setEmailContent(`Hello ${userName},
            
            Please be advised that you have an upcoming visitation. See details below:
                - DATE: ${dateOfVisit}
                - Client: ${farmName}
                - ADDRESS: ${farmAddress}
            `)
            handleEmailFormOpen()
        }

    }
    
    //function to be called onLoad of this* component
    //the functon will fetch the data from the db via api
    useEffect(() => {

        let clientList = [];
        let clientIdx = 1;
        //set the condition to be checked before fetching, this is neccessary while using useEffect
        if(fetching) {
            if(viewFarms === ""){
                setStoredFilter(userName)
            }
            else{
                setStoredFilter(viewFarms)
            }
            
            // fetch customer list first
            const fetchClientData = async () => {
                fetch(`https://jdi-staging.maps.geoint.africa/jdipwa/api/clients/${userName}`)
                .then(res => res.json())
                .then((data) => {

                    /*
                    setCellNumber(filteredBusiness[0].cell_no)
                    setEmail(filteredBusiness[0].email)
                    setLoContactName(`${filteredBusiness[0].client_name} ${filteredBusiness[0].client_surname}`)
                    setFarmType(filteredBusiness[0].f_user_type)
                    setProfile(filteredBusiness[0].f_client_profile)
                    */

                    data.map((record) => {
                        let namedRecord = {
                            id: clientIdx, 
                            label: record.business_name, 
                            value: record.business_name,
                            cell_no: record.cell_no,
                            email: record.email,
                            f_user_type: record.f_user_type,
                            f_client_type: record.f_client_profile,
                            client_fullname: `${record.client_name} ${record.client_surname}`,
                            client_name: record.client_name,
                            client_surname: record.client_surname
                        };

                        clientIdx+=1;

                        
                        return clientList.push(namedRecord)
    
    
                    });

                    setClients(clientList)
                    // console.log(clientList)
                })
                .catch((error) => {
                    console.error(error)
                });
            }
        
            //call the function
            fetchClientData()
            // make sure to catch any error
            .catch(console.error);

            // declare the data fetching function
            const getDataFromAPI = async () => {

                let isUserSuper = isUserAdmin; 
                let replacementName;
                
                if(isUserSuper) {
                    replacementName = userName;
                }
                else{
                    replacementName = userName;
                }

                fetch(`https://jdi-staging.maps.geoint.africa/jdipwa/api/farms/${replacementName}`) 
                .then((res) => res.json())
                .then((data) => {

                    let theData = data.features;

                    let filteredData = theData.map((record) => {

                        return record.properties
                    })
                    // setFarms([filteredData])
                    setFarms(filteredData)
                    // console.log(filteredData)
                })
                .then(() => {
                    setFetching(false)
                })
                .catch((error) => {
                    console.error(error)
                });
            }
            // call the function
            getDataFromAPI()
            .catch(console.error);

            const getVisitationStatus = async () => {

                let notificationIdx = 0;
                let prelimNotifiList = [];

                fetch(`https://jdi-staging.maps.geoint.africa/jdipwa/api/visit-status/${userName}`) 
                .then((res) => res.json())
                .then((data) => {

                    
                    setVisitStatus(data)
                    // console.log(actualData)

                    var today = new Date();
                    var nextMonth = new Date(today.getFullYear(), today.getMonth(), today.getDate()+30);
                    var nextWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate()+7);
                    //console.log(`${dateTimeAF}  |  ${nextMonth}`);

                    data.map((farm, indx) => {
                        if(farm.visit_status === "Lapsed") {

                            notificationIdx += 1;

                            let record = {id: notificationIdx, note: farm.farm_name};

                            prelimNotifiList.push(record);

                            return toast({
                                position:'top-right',
                                title: 'Visitation Lapsed!',
                                description: `${farm.business_name}, is overdue for a visit.`,
                                status:'error',
                                duration: null,
                                isClosable: true,
                            });
                        }
                        else if(farm.visit_status === "On Track"){

                            notificationIdx += 1;

                            let record = {id: notificationIdx, note: farm.farm_name};

                            prelimNotifiList.push(record);

                            if(farm.future_visit <  nextMonth) {
                                return toast({
                                    position: 'top-left',
                                    title: 'Upcoming Visitation',
                                    description: farm.future_visit === null ? `${farm.business_name}, has an upcoming visitation` : `${farm.business_name}, has an upcoming visitation on ${farm.future_visit}.`,
                                    status: 'success',
                                    duration: null,
                                    isClosable: true,
                                    // : notificationAction(farm.future_visit, farm.farm_name)
                                });
                            }
                        }
                    })
                }) 


                setNotification(prelimNotifiList);
            }    
            // call the function
            // getVisitationStatus()
            
            
        }
        checkForFarmFilterChange()
    }, fetching, viewFarms);

    const checkForFarmFilterChange = () => {

        let currentFilter = viewFarms;
            
        if(storedFilter === currentFilter){
            // Do Nothing
        }
        else {

            let filteredRows = farms.filter((row) => {
                return row.marketer.includes(currentFilter);
            });

            setFarms(filteredRows)
            setStoredFilter(currentFilter)
        }
    }
    

    const handleFormOpen = () => {

        setFormOpen(!formOpen);

        resetForm();

        setBusinessName(rowParams.row.business_name === null ? "" : rowParams.row.business_name);
        setLoContactName(rowParams.row.contact_name === null ? "" : rowParams.row.contact_name);
        setLoContactSurname(rowParams.row.client_surname === null ? "" : rowParams.row.client_surname);
        setFarmAddress(rowParams.row.address === null ? "" : rowParams.row.address);
        setCellNumber(rowParams.row.phone === null ? "" : rowParams.row.phone);
        setEmail(rowParams.row.email === null ? "" : rowParams.row.email);
        setFarmID(rowParams.row.farm_id);
        setFarmName(rowParams.row.farm_name === null ? "" : rowParams.row.farm_name);
        setProfile(rowParams.row.farm_profile);
        setFarmType(rowParams.row.farm_type);

    }

    const resetForm = () => {

        setNewBusiness(false);
        setBusinessName("");
    }

    const handleFormSubmit = (e) => {

        e.preventDefault();

        setSip("Pending");

        // check if business name is the same as farm name
        // if(businessName.toLowerCase() === farmName.toLowerCase()) {

        //     window.alert("")
        // }

        let contactOwner;
        let allowNoAddress;
        let defaultAddress = {address: '20 Station St, Swellendam, 6740'};
        let defaultLocation = {lat: -34.02693430123456, lon: 20.44368273175919};
        let overrideAddress = false;

        if(leaseType === "rental") {
            contactOwner = false;
        }
        else {
            contactOwner = true;
        }

        if(userLocation === undefined) {
            
            allowNoAddress = window.confirm('WARNING: You are about to complete the visitation without checking in. Click OK to continue');

            if(allowNoAddress) {

                window.alert(`Defaulting check in address to ${defaultAddress.address} | lat:${defaultLocation.lat}, lon:${defaultLocation.lon}`);

                overrideAddress = true;
            }

            else {
                return
            }
        }

        let formData = {
            'farmName': farmName,
            'businessName': businessName, 'farmType': farmType, 'contactOwner': contactOwner, 
            'profile': profile, 'email': email, 'Address': farmAddress,
            'phone':cellNumber, 'additionalTel': additionalNumber, 'province': province,
            'visitReason': reasonFVisit, 'comments': comments,
            'userId': userID, 'username': userName, 'coords': userLocation,
            'geoAddress': userGeoAddress, 'farmId': farmId, 'contactName': loContactName, 
            'contactSurname': loContactSurname, 'newBusiness': newBusiness, 'overrideAddress': overrideAddress
        }

        // warn user about missing or incorrect values

        if(businessName === '') {
            setSip("");

            return window.alert("Oops! Looks like you have not selected a BUSINESS NAME. Please check your form input.");
        }

        if(farmType === ''  || farmType === 'UNDEFINED') {
            setSip("");

            return window.alert("Oops! Looks like you have not selected a FARM PROFILE. Please check your form input.");
        }

        if(profile === '' || profile === 'UNDEFINED') {
            setSip("");

            return window.alert("Oops! Looks like you have not selected a FARM TYPE. Please check your form input.");
        }

        if(reasonFVisit === '') {
            setSip("");

            return window.alert("Oops! Looks like you have not entered a REASON FOR VISIT. Please check your form input.");
        }

        if(loContactName === '') {
            setSip("");

            return window.alert("Oops! Looks like you have not entered a CONTACT PERSON. Please check your form input.");
        }

        if(loContactSurname === '') {
            setSip("");

            return window.alert("Oops! Looks like you have not entered the CONTACT PERSON SURNAME. Please check your form input.");
        }

        if(cellNumber === '') {
            setSip("");

            return window.alert("Oops! Looks like you have not entered a CONTACT NUMBER. Please check your form input.");
        }

        if(farmId === 0 || null) {
            setSip("");

            return window.alert("Oops! Farm ID is incorrect. Please select the correct farm");
        }

        if(farmName === '' || null) {
            setSip("");

            return window.alert("Oops! Farm Name is incorrect. Please enter the correct farm name");
        }

        let confirmation;

        // console.log(formData);
        if(businessName !== '' && farmType !== "") {
            confirmation = window.confirm(`
            Confirm:
            \nFarm Name:${farmName} | New Business:${newBusiness}| Business Name:${businessName} | Lease Type:${leaseType} | Farm Type:${farmType} | Profile:${profile} | Farm Address:${farmAddress} | Contact Name:${loContactName} | Cell Number:${cellNumber} | Additional Contact:${additionalNumber}| Email:${email} | Visit Reason: ${reasonFVisit} | Comments: ${comments}
            `);
        }
        else {
            window.alert("Oops!! Form is incomplete.");
        }
        

        if(confirmation) {

            fetch("https://jdi-staging.maps.geoint.africa/jdipwa/api/farm/edits", {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(formData)
            })
            .then(res => res.json())
            .then((result) => {
                // console.log(result)
                if(result.success) {

                    resetState();
                    setFormOpen(!formOpen);
                    handleClose();
                    setFetching(true);

                    return toast({
                        position: 'bottom-right',
                        title: result.message,
                        // description: "Welcome to MapstackUJ.com",
                        status: 'success',
                        duration: 1000,
                        isClosable: true,
                    });
                }
                else{
                    resetState();

                    return toast({
                        position: 'bottom-right',
                        title: 'Form submission was not successful. Please try again.',
                        // description: "Welcome to MapstackUJ.com",
                        status: 'warning',
                        duration: 3000,
                        isClosable: true,
                    });
                }
            })
            .then(() => {
                setFetching(false)
            })

            // posting to visits first so that you can use the business name as a target when populating the goelocation
            // and the visit_id to the 
            setTimeout(() => {
                fetch("https://jdi-staging.maps.geoint.africa/jdipwa/api/visits", {
                headers: {
                    'Content-Type': 'application/json'
                },
                method: 'POST',
                body: JSON.stringify(formData)
            })
            .then(res => res.json())
            .then((result) => {
                // console.log(result)

                if(result.success) {
                    return toast({
                        position:'bottom-right',
                        title: 'Visitation form submitted successfully',
                        // description: "Welcome to MapstackUJ.com",
                        status: 'success',
                        duration: 3000,
                        isClosable: true,
                    });
                }
                else{
                    return toast({
                        position: 'bottom-right',
                        title: 'Visitation form submission was not successful. Please try again.',
                        // description: "Welcome to MapstackUJ.com",
                        status: 'warning',
                        duration: 3000,
                        isClosable: true,
                    });
                }
            });
            }, 1000)
        }
    }


    if(!fetching && farms.length !== 0) {
        return (
            <ThemeProvider theme={MuiTheme}>
            <Container fluid={true} >
                
                {/* {
                emailFormOpen 
                    ? (<emailModal 
                        emailAddress={notificationMail} 
                        emailContent={emailContent} 
                        handleEmailFormClose={handleEmailFormClose}
                        emailFormOpen={emailFormOpen}
                        >
                        </emailModal>
                    )
                    :
                    <></>
                } */}
                {
                open 
                ? (<Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        style={{overflowY: 'scroll'}}
                    >
                    {formOpen ? (
                    <Box sx={style}>
                        <IconButton aria-label="close" size="large" onClick={handleClose} style={{left: "95%", top: "0"}}>
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                        <h5><b>Farm Visitation </b></h5>
                            <br/>
                            <br/>
                            <form autoComplete="off" onSubmit={(e) => handleFormSubmit(e)} >
                                <div onChange={(e) => {
                                    setNewBusiness(e.target.value)
                                }}>
                                    <input color='green' id="newBizInput" type="radio" value={newBusiness} name="isNewBusiness" style={{marginLeft: '8px'}}/> New Business
                                </div>
                                <Button 
                                        size='small' 
                                        type='reset' 
                                        onClick={resetForm} 
                                        style={{color:'orangered', borderRadius:'3px'}}
                                >
                                        reset 
                                </Button>

                                <br/>
                                <br/>
                                {
                                    newBusiness ? (
                                        <>
                                            <label for="clientsSelect">New Business Name *</label>
                                            <Input
                                                placeholder="New Business Name"
                                                id="newBizName"
                                                value={businessName}
                                                onChange={e => setBusinessName(e.target.value)}
                                                style={{
                                                    width: "100%",
                                                    padding: "6px 10px",
                                                    margin: "8px 0",
                                                    display: "inline-block",
                                                    border: "1px solid #E86222",
                                                    borderRadius: "4px",
                                                    boxSizing: "border-box",
                                                }}
                                            />
                                        </>
                                    )
                                    : (
                                    <>
                                        <label for="clientsSelect">Existing Business Name *</label>
                                            {
                                                rowParams.row.business_name === null 
                                                ? 
                                                <Select
                                                    options={clients}
                                                    placeholder={`Select a Business`}
                                                    value={selectedOptions}
                                                    onChange={handleSelect}
                                                    isSearchable={true}
                                                    style={{
                                                        width: "100%",
                                                        padding: "6px 10px",
                                                        margin: "8px 0",
                                                        display: "inline-block",
                                                        border: "1px solid #E86222",
                                                        borderRadius: "4px",
                                                        boxSizing: "border-box",
                                                    }}
                                                    id="clientsSelect"
                                                    required
                                                />
                                                :
                                                // <Select
                                                //     defaultInputValue={rowParams.row.business_name}
                                                //     options={clients}
                                                //     // placeholder={rowParams.row.business_name}
                                                //     value={selectedOptions}
                                                //     onChange={handleSelect}
                                                //     isSearchable={true}
                                                //     style={{
                                                //         width: "100%",
                                                //         padding: "6px 10px",
                                                //         margin: "8px 0",
                                                //         display: "inline-block",
                                                //         border: "1px solid #E86222",
                                                //         borderRadius: "4px",
                                                //         boxSizing: "border-box",
                                                //     }}
                                                //     id="clientsSelect"
                                                //     required
                                                // />
                                                <Input
                                                    onChange={e => setBusinessName(e.target.value)}
                                                    defaultInputValue={businessName.length >=0 
                                                        ? businessName
                                                        : rowParams.row.business_name}
                                                    value={ businessName.length >=0 
                                                        ? businessName
                                                        : rowParams.row.business_name
                                                    }
                                                    style={{
                                                        width: "100%",
                                                        padding: "6px 10px",
                                                        margin: "8px 0",
                                                        display: "inline-block",
                                                        border: "1px solid #E86222",
                                                        borderRadius: "4px",
                                                        boxSizing: "border-box",
                                                    }}
                                                    id="clientsSelect"
                                                    required
                                                >
                                                {rowParams.row.business_name}
                                                </Input>
                                            }
                                            
                                    </>
                                    )
                                }
                               
                                <label for="farmAddress">Business Physical Address</label>
                                {
                                    rowParams.row.address !== "" 
                                    
                                    ? (
                                        <Input
                                            id="existingFarmAddress"
                                            value={rowParams.row.address}
                                            onChange={e => setFarmAddress(e.target.value)}
                                            style={{
                                                width: "100%",
                                                padding: "6px 10px",
                                                margin: "8px 0",
                                                display: "inline-block",
                                                border: "1px solid #E86222",
                                                borderRadius: "4px",
                                                boxSizing: "border-box",
                                            }}
                                        />
                                      )
                                    :
                                      <></>
                                }
                                <Input 
                                    type="search" 
                                    list="searchText" 
                                    id="farmAddress" 
                                    // value={farmAddress}
                                    className="form-control"
                                    hidden = {rowParams.row.address !== "" ? true : false}
                                    onKeyUp={searchLoc} 
                                    placeholder="Farm Address" 
                                    style={{
                                        width: "100%",
                                        padding: "6px 10px",
                                        margin: "8px 0",
                                        display: "inline-block",
                                        border: "1px solid #E86222",
                                        borderRadius: "4px",
                                        boxSizing: "border-box",
                                    }}
                                />
                                {searchResults.length <= 10 ? (
                                    <ul id="searchText" style={{alignSelf:'start'}}>
                                        {searchResults.map((data, idx) => (
                                            <li 
                                               style={{
                                                fontSize: '14px',
                                                minWidth: '72%', 
                                                maxWidth: '72%', 
                                                padding: '4px', 
                                                border: '1px solid gray', 
                                                borderRadius: '4px',
                                                backgroundColor: '#1a2846',
                                                color: 'white',
                                               }}
                                            >
                                                <button 
                                                    key={idx}
                                                    id={`listBtn-${idx}`}
                                                    value={`${data.address.freeformAddress}`}
                                                    onClick={
                                                        (e) => handleAddressClick(e)
                                                    } 
    
                                                    style={{
                                                        fontSize: '14px',
                                                        maxWidth: '100%', 
                                                        color: 'white',
                                                        // backgroundColor: '#1a2846',
                                                        
                                                    }}
                                                >
                                                    {`${data.address.freeformAddress}`}
                                                </button>
                                            </li>
                                        ))}
                                    </ul>)
                                : null
                                }   
                                <label for="farm_name">Farm Name</label>
                                <input 
                                    onChange={e => setFarmName(e.target.value)}
                                    value={
                                        farmName.length >= 0 ? farmName : rowParams.row.farm_name
                                    }
                                    type='text'
                                    style={{
                                        width: "100%",
                                        display: "inline-block",
                                        padding: "6px 10px",
                                        border: "1px solid #E86222",
                                        borderRadius: "4px",
                                        boxSizing: "border-box",
                                        margin: "8px 0",
                                    }}
                                    id='farm_name'
                                />
                                <br/>             
                                <label for="leaseType">Farm Lease Type</label>
                                <select 
                                    id="leaseType" 
                                    name="leaseType" 
                                    placeholder='Farm lease type' 
                                    onChange={e => setLeaseType(e.target.value)}
                                    style={{
                                            width: "100%",
                                            padding: "6px 10px",
                                            margin: "8px 0",
                                            display: "inline-block",
                                            border: "1px solid #E86222",
                                            borderRadius: "4px",
                                            boxSizing: "border-box",    
                                    }}
                                    // onBeforeInput={setLeaseType(rowParams.row.contact_owner)}
                                >
                                    <option value={"owner"}
                                    >
                                        Owner
                                    </option>
                                    <option value="rental">Rental</option>
                                    <option value="owner">Owner</option>
                                </select>
                                <label for="visitationFreq">Farm Type *</label>
                                <select 
                                    id="visitationFreq" 
                                    name="visitationFreq" 
                                    placeholder='Visitation Frequency' 
                                    onChange={e => setProfile(e.target.value)}
                                    // onBeforeInput={setProfile(rowParams.row.farm_type)}
                                    style={{
                                            width: "100%",
                                            padding: "6px 10px",
                                            margin: "8px 0",
                                            display: "inline-block",
                                            border: "1px solid #E86222",
                                            borderRadius: "4px",
                                            boxSizing: "border-box",
                                            
                                    }}
                                    required
                                >
                                    <option value={rowParams.row.farm_type === null
                                        ? "UNDEFINED"
                                        : rowParams.row.farm_type }
                                    >
                                        {rowParams.row.farm_type}
                                    </option>
                                    <option value="A">A - Monthly visitation</option>
                                    <option value="B">B - Visitation every 3 months</option>
                                    <option value="C">C - Visitation every 6 months</option>
                                    <option value="D">D - Visitation every 12 months</option>
                                    <option value="E">E - Visitation every 18 months</option>
                                </select>
                                <br />
                                <label for="profile">Farm Profile *</label>
                                <select 
                                    id="profile" 
                                    name="profile" 
                                    placeholder='Select profile' 
                                    onChange={e => setFarmType(e.target.value)}
                                    // onBeforeInput={setFarmType(rowParams.row.farm_profile)}
                                    style={{
                                            width: "100%",
                                            padding: "6px 10px",
                                            margin: "8px 0",
                                            display: "inline-block",
                                            border: "1px solid #E86222",
                                            borderRadius: "4px",
                                            boxSizing: "border-box",
                                            
                                    }}
                                    required
                                > 
                                    <option value={rowParams.row.farm_profile === null 
                                        ? "UNDEFINED"
                                        : rowParams.row.farm_profile
                                    }>{rowParams.row.farm_profile} </option>
                                    <option value="JDI">Customer only has JDI equipment</option>
                                    <option value="JDI/OPPO">Customer has JDI equipment as well as competitor brands</option>
                                    <option value="OPPO">Customer does not have any JDI equipment</option>
                                    <option value="NONE">Customer does not have any equipment </option>
                                </select>
                                <br />
                                <label for="reason">Reason for visit *</label>
                                <br />
                                <select id="reason" name="reason" placeholder='Select Reason' onChange={e => setReasonFVisit(e.target.value)}
                                    style={{
                                            width: "100%",
                                            padding: "6px 10px",
                                            margin: "8px 0",
                                            display: "inline-block",
                                            border: "1px solid #E86222",
                                            borderRadius: "4px",
                                            boxSizing: "border-box",
                                    }}
                                    required
                                >
                                    <option value="None">Select </option>
                                    <option value="Courtesy">Courtesy</option>
                                    <option value="Quotation">Quotation</option>
                                    <option value="Delivery">Delivery</option>
                                    <option value="Inspection">Inspection</option>
                                    <option value="Problem">Problem</option>
                                    <option value="Updating Farm Information">Updating Farm Information</option>
                                </select>
                                <br />
                                <label for="lo_name">Business Contact Person *</label>
                                <input 
                                    onChange={e => setLoContactName(e.target.value)}
                                    value={
                                        loContactName.length >= 0 ? loContactName : rowParams.row.contact_name
                                    }
                                    style={{
                                        width: "100%",
                                        padding: "6px 10px",
                                        margin: "8px 0",
                                        display: "inline-block",
                                        border: "1px solid #E86222",
                                        borderRadius: "4px",
                                        boxSizing: "border-box",
                                    }}
                                    type='text'
                                    id='lo_name'    
                                    required                            
                                />
                                <label for="lo_surname">Business Contact Surname *</label>
                                <input 
                                    onChange={e => setLoContactSurname(e.target.value)}
                                    // onBeforeInput={setLoContactName(rowParams.row.client_surname)}
                                    value={
                                        loContactSurname.length >= 0 ? loContactSurname : rowParams.row.client_surname
                                    }
                                    style={{
                                        width: "100%",
                                        padding: "6px 10px",
                                        margin: "8px 0",
                                        display: "inline-block",
                                        border: "1px solid #E86222",
                                        borderRadius: "4px",
                                        boxSizing: "border-box",
                                    }}
                                    type='text'
                                    id='lo_surname'
                                    required                          
                                />


                                <label for="cell">Business Contact Person Cellphone *</label>
                                <input 
                                    label="Cell number"
                                    onChange={e => setCellNumber(`${e.target.value}`)}
                                    style={{
                                        width: "100%",
                                        padding: "6px 10px",
                                        margin: "8px 0",
                                        display: "inline-block",
                                        border: "1px solid #E86222",
                                        borderRadius: "4px",
                                        boxSizing: "border-box",
                                    }}
                                    type="number"
                                    id="cell"
                                    placeholder="e.g) 083555999 (Digits Only)"
                                    value={cellNumber.length >= 0 ? cellNumber : rowParams.row.phone }
                                    required
                                />
                                <label for="additionalNumber">Additional Contact Number</label>
                                <input 
                                    label="Additional Number"
                                    onChange={e => setAdditionalNumber(`${e.target.value}`)}
                                    style={{
                                        width: "100%",
                                        padding: "6px 10px",
                                        margin: "8px 0",
                                        display: "inline-block",
                                        border: "1px solid #E86222",
                                        borderRadius: "4px",
                                        boxSizing: "border-box",
                                    }}
                                    type='number'
                                    id="additionalNumber"
                                    placeholder="e.g) 083555999 (Digits Only)"
                                    value={additionalNumber.length >= 0 ? additionalNumber : rowParams.row.additional_tel_no}

                                />
                                <label for="lo_email">Business Email</label>
                                <input 
                                    onChange={e => setEmail(e.target.value)}
                                    value={email.length >=0 ? email : rowParams.row.email}
                                    type='email'
                                    style={{
                                        width: "100%",
                                        padding: "6px 10px",
                                        margin: "8px 0",
                                        display: "inline-block",
                                        border: "1px solid #E86222",
                                        borderRadius: "4px",
                                        boxSizing: "border-box",
                                    }}
                                    id='lo_email'
                                />
                                <br />
                                <label for="comments">Comments</label>
                                <br />
                                <textarea
                                    onChange={e => setComments(e.target.value)}
                                    value={comments}
                                    type='text'
                                    style={{
                                        width: "100%",
                                        height: "50px",
                                        padding: "6px 10px",
                                        margin: "8px 0",
                                        display: "inline-block",
                                        border: "1px solid #E86222",
                                        borderRadius: "4px",
                                        boxSizing: "border-box",
                                        fontFamily:"sans-serif",
                                        fontSize:"1.0em"
                                    }}
                                />
                                <br />
                                <Row>
                                    <Col>
                                        
                                            
                                            <Button variant="contained" style={{width: "100%", padding: "5px"}} type="submit" onClick={(e) => {
                                                // setFarmID(rowParams.row.farm_id)
                                                // setBusinessName(businessName !== "" ? businessName : rowParams.row.business_name)
                                                // setFarmAddress(farmAddress !== "" ? farmAddress : rowParams.row.postal_address)
                                                // setCellNumber(cellNumber !== "" ? cellNumber : rowParams.row.cell_no)
                                                // setEmail(email !== "" ? email : rowParams.row.email)
                                                // setTelephone(telephone !== "" ? telephone : rowParams.row.telephone)
                                                // setProvince(province !== "" ? province : rowParams.row.province)  
                                                
                                                handleFormSubmit(e)                                        
                                            }} 
                                            >
                                                {suInProg === "Pending" ?
                                                <>Sending</>

                                                :

                                                <>Submit</>
                                                }
                                            </Button>
                                    </Col>
                                    <Col>
                                        <Button variant="contained" style={{width: "100%", backgroundColor:"darkorange"}} onClick={handleFormClose}>
                                            cancel
                                        </Button>
                                    </Col>
                                </Row>    
                                
                        </form>
                        </Box> 
                    )
                    : (
                        <Box sx={style}>
                        <IconButton aria-label="close" size="large" onClick={handleClose} style={{left: "90%", top: "-5"}}>
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                        <h6><b>Farm Information </b></h6>
                        <br></br>
                        <Row>
                            <Col>
                                <CheckIn />
                            </Col>
                            <Col>
                                {/* SPACER */}
                            </Col>
                            <Col>
                                {/* SPACER */}
                            </Col>
                        </Row>    
                        <hr></hr>
                        <Row>
                            <Col>
                                ID: {rowParams.row.farm_id}
                            </Col> 
                        </Row>
                        <hr></hr>
                        <br></br>
                        <Typography variant="body2" component="p">
                            <b style={{color: '#E86224'}}>Farm Name: </b> {rowParams.row.farm_name}
                        </Typography>
                        <Typography variant="body2" component="p">
                            <b style={{color: '#E86224'}}>Business Name: </b> {rowParams.row.business_name === null ? "not defined" : rowParams.row.business_name}
                        </Typography>
                        <Typography variant="body2" component="p">
                            <b style={{color: '#E86224'}}>LPI: </b> {rowParams.row.lpi} 
                        </Typography>
                        <Typography variant="body2" component="p">
                            <b style={{color: '#E86224 '}}>Farm Type: </b> {rowParams.row.farm_type === null ? "not defined" : rowParams.row.farm_type}
                        </Typography>
                        <Typography variant="body2" component="p">
                            <b style={{color: '#E86224'}}>Marketer: </b> {rowParams.row.marketer_fullname === null ? "unassigned" : rowParams.row.marketer_fullname} 
                        </Typography>
                        <Typography variant="body2" component="p">
                            <b style={{color: '#E86224'}}>Farm Profile / Visitation Frequency: </b> {rowParams.row.farm_profile === null ? "not categorized" : rowParams.row.farm_profile}
                        </Typography>
                        <Typography variant="body2" component="p">
                            <b style={{color: '#E86224'}}>Branch: </b> {rowParams.row.branch === null ? "unassigned" : rowParams.row.branch}
                        </Typography>
                       
                        <hr></hr>
                        <br></br>
                        <h6><b>Farm Location </b></h6>
                        <br></br>

                        <Typography variant="body2" component="p">
                            <b style={{color: '#2F8D46'}}>Address: </b> {rowParams.row.address === null ? "-" : rowParams.row.address}
                        </Typography>
                        <Typography variant="body2" component="p">
                            <b style={{color: '#2F8D46'}}>Province: </b> {rowParams.row.province === null ? "-" : rowParams.row.province}
                        </Typography>
                        <Typography variant="body2" component="p">                       

                            <b style={{color: '#2F8D46'}}>Town: </b> {rowParams.row.town === null ? "not defined" : rowParams.row.town}
                        </Typography>
                        <Typography variant="body2" component="p">
                            <b style={{color: '#2F8D46'}}>Local Municipality: </b> {rowParams.row.local_municipality === null ? "not defined" : rowParams.row.local_municipality}
                        </Typography>
                        <Typography variant="body2" component="p">
                            <b style={{color: '#2F8D46'}}>Region: </b> {rowParams.row.major_region === null ? "not defined" : rowParams.row.major_region}
                        </Typography>
                        <Typography variant="body2" component="p">
                            <b style={{color: '#2F8D46'}}>ERF: </b> {rowParams.row.erf_farm_no === null ? "not defined" : rowParams.row.erf_farm_no}
                        </Typography>
                        <hr></hr>
                        <br></br>
                        <h6><b>Farm Contact Details </b></h6>
                        <br></br>
                        <Typography variant="body2" component="p">
                            <b style={{color: 'gray'}}>Contact Person: </b> {rowParams.row.contact_name === null ? "not defined" : rowParams.row.contact_name}
                        </Typography>
                        <Typography variant="body2" component="p">
                            <b style={{color: 'gray'}}>Contact Details: </b> {rowParams.row.phone === null ? "not defined" : rowParams.row.phone}
                        </Typography>
                        <Typography variant="body2" component="p">
                            <b style={{color: 'gray'}}>Email: </b> {rowParams.row.email === null ? "not defined" : rowParams.row.email}
                        </Typography>
                        <Typography variant="body2" component="p">
                            <b style={{color: 'gray'}}>Additional Info: </b> {rowParams.row.farm_comments === null ? "-" : rowParams.row.farm_comments}
                        </Typography>
                        <hr></hr>
                        <br></br>
                        <Row>
                            <Col>
                            <Button variant="contained" size='small' style={{width: "100%"}} onClick={handleFormOpen}>
                                Complete Visitation
                            </Button>
                            </Col>
                            <Col>
                            <Button variant="contained" size='small' style={{width: "100%", backgroundColor:"darkorange"}} onClick={handleClose}>
                                Close Window
                            </Button>
                            </Col>
                        </Row>
                        
                    </Box>
                    )
                }
                </Modal>)
                : (<></>)
                }
                <Row>
                    <Col >
                        <div onChange={(e) => {
                                setCategory(e.target.value)
                            }}>
                                {/* Name, LPI branch, Farm Name and business name */}
                                <input type="radio" value="farm_name" name="searchCategory" style={{marginRight: '5px'}}/> Farm Name 
                                <input type="radio" value="LPI" name="searchCategory" style={{marginLeft: '8px'}}/> LPI
                                <input type="radio" value="business_name" name="searchCategory" style={{marginLeft: '10px'}}/> Business Name
                                <br/>
                                <br/>                             
                            </div>
                        <InputGroup>
                            <Input
                                onChange={
                                    (searchVal) => searchVal.target.value.length > 2 
                                    ? requestSearch(searchVal.target.value) 
                                    : console.log('')
                                }
                                id='searchBar'
                                placeholder={`search by ${searchCategory}`}
                                style={{width:'100%'}}
                            />
                            <Button 
                                size='small' 
                                type='reset' 
                                onClick={cancelSearch} 
                                style={{color:'orangered', borderRadius:'3px'}}
                            >
                                    reset
                            </Button>
                        </InputGroup>
                        <br/>
                        {
                            searchCategory.length > 2 
                            ? (
                                <DataTable
                                    rows={farms} 
                                    columns={columns}
                                    loading={fetching}
                                    sx={deployTableStyles}
                                    pageSizeOptions={[5, 10, 25]}
                                    checkboxSelection={checkboxSelection}
                                    onRowClick={(params , event) => {
                                        setParams(params);
                                        handleOpen()  ;                          
                                    }}
                                    getRowId={(rows) => rows.farm_id}
                                />
                            )
                            :
                            ( 
                                <h4 style={{color:'darkgrey'}}>
                                    Search Results Table
                                </h4>
                            )
                        }
                    </Col>
                </Row>
            </Container>
        </ThemeProvider>  
        )
    }else{
        return (
            <ThemeProvider theme={MuiTheme}>

            <Container fluid>
                <Row>
                    <Button
                        
                        onClick={cancelSearch}
                        id='searchBarClear'
                        variant="outlined"
                        style={{width: "100%"}}
                    >
                        Load Farm Data
                    </Button>

                    <Col>
                            <p style={{color:'darkgrey'}}>
                                loading data..
                            </p>
                        <CircularProgress isIndeterminate color='blue.500' marginLeft={'45%'}/>
                        <br/>
                         
                    </Col>
                </Row>

            </Container>
            </ThemeProvider>
        )
    }
}

export default FarmsTable;